import { faBalanceScale, faFileContract, faUsdSquare, faUsersCog, faFileInvoiceDollar } from '@fortawesome/pro-light-svg-icons';
import {
  faList, faUserCheck, faUserSlash, faUserClock, faUserMinus, faUserPlus, faClock, 
  faUsersClass,faMoneyBill,faExclamationCircle, faNotesMedical,
} from '@fortawesome/pro-solid-svg-icons';

const routes = (permisos) => {

  return [
    {
      subMenuTitle: "",
      subRoutes: [
        {
          path: "/lista-colaboradores",
          name: "Activos",
          icon: faUserCheck,
        },
        {
          path: "/proceso",
          name: "En proceso",
          icon: faUserClock,
        },
        {
          path: "/lista-bajas",
          name: "Bajas",
          icon: faUserSlash,
        },
        {
          path: "/altas",
          name: "Dar de alta",
          icon: faUserPlus,
        },

        {
          path: "/bajas",
          name: "Dar de baja",
          icon: faUserMinus,
        },
      ],
    },
    {
      subMenuTitle: "Calendario",
      subRoutes: [],
    },
    {
      subMenuTitle: "",
      subRoutes: [
        {
          path: "/horas-extra",
          name: "Horas Extra",
          icon: faClock,
        },
        {
          path: "/faltas-masivo",
          name: "Faltas",
          icon: faUsersClass,
        },
        {
          path: "/ajustes-masivo",
          name: "Ajustes",
          icon: faUsersCog,
        },
      ],
    },
    
  ]
};
export default routes;
