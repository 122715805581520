import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchListaPermisos } from '../../../store/permisosLaboralesDuck';
import { Button, FormGroup } from 'reactstrap';
import colors from '../../../constans/colors';
import TableBody from './TableBody';
import TableCommon from '../VacacionesViews/TableVacaciones';
import { TableContainer, LabelForms } from '../VacacionesViews/styled';
import FirstAdd from '../../../components/ui/FirstAdd';
import { saveAs } from 'file-saver';
import axios from 'axios';
import moment from 'moment';
const api = process.env.REACT_APP_API_URL;
const PermisosLaboralesView = ({history, disabled = false}) => {
    const dispatch = useDispatch();
    const { listaPermisosLaborales=[] } = useSelector((state) => state.permisos);
    const { permisos = {} } = useSelector((state) => state.user);
    const colaborador = useSelector((state) => state.colaborador);
    const { _id } = colaborador;
    const headers = {
      headers: {
        token: localStorage.getItem("pv2token"),
      },
    };
    useEffect(() => {
      (async () => {
        try {
          await dispatch(fetchListaPermisos());
        } catch (error) {}
      })();
    }, [dispatch]);

    const downloadPdf = async (e, type, permiso) => {      
      e.preventDefault();      
      const { periodoInicial, periodoFinal, tipoPermiso, observaciones, personaAutoriza } = permiso;
      const data = {periodoInicial, periodoFinal, tipoPermiso, observaciones, personaAutoriza};
      const file = type === 'Solicitud' 
      ? await axios.post(`${api}/permisos-laborales/generar-oficio/`, { ...data, colaborador: _id }, headers) 
      : await axios.post(`${api}/permisos-laborales/generar-autorizacion/`, { ...data, colaborador: _id }, headers)
    
      let byteString = atob(file.data);
    
      let ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      let blob = new Blob([ia], { type: 'application/pdf' });
      saveAs(blob, `${type}-${moment().format('YYYYMMDD')}`)
    }

    if(listaPermisosLaborales && listaPermisosLaborales.length > 0)
      return (
        <>
          <FormGroup row style={{ marginLeft: 16 }}>
            <h5>Permisos Laborales</h5>
          </FormGroup>
          <TableContainer style={{marginLeft:30, marginTop:10}}>
            <TableCommon
              keysAceepted={['Inicio', 'Fin', 'Días', 'Sueldo','','Ext.','Descargar']}
              extraTag={{ key: 'Valor Total', value: 1200 }}
              striped
            >
            <TableBody listaPermisosLaborales={listaPermisosLaborales} downloadPdf={downloadPdf}/>
            </TableCommon>
            {
              !disabled && <Button type="button" onClick={()=>{
                history.push('/permisos/agregar')
              }} 
              style={{background:colors.secondary,width:'203px',height:'51px',borderRadius:'4px'}}
              hidden={permisos.colaboradorPermisos !== 'write'}>+ Agregar</Button>
            }
          </TableContainer>
        </>
      );
    else return (
      <div style={{textAlign: "center"}}>
      {
          disabled 
          ? <div style={{marginTop: "20%", fontWeight: "bold"}}>Sin permisos laborales</div>
          : <FirstAdd mensaje="Agrega aquí los permisos laborales de tu colaborador" vista='permisos' disabled={permisos.colaboradorPermisos !== 'write'}/>
      }
      </div>
    )
};

export default PermisosLaboralesView;