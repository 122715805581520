import { faLayerPlus, faBars } from '@fortawesome/pro-solid-svg-icons';
const cuenta_routes =[

    {
        subMenuTitle: '',
        icon:'',
        subRoutes: [

            /*{
                path: '/crear-empresa',
                name: 'Crear empresa',
                icon:  faLayerPlus
            },*/
            {
                path: '/empresa',
                name: 'Listado',
                icon: faBars

            },
            // {
            //     path: '',
            //     name: 'Dar de baja',
            //     icon : faLayerMinus
            // },
            // {
            //     path: '/',
            //     name: 'Método de pago',
            //     icon:faCreditCard,
            // },
            // {
            //     path: '',
            //     name: 'Facturación',
            //     icon: faFileInvoice
            // }
        ]
    }
]
export default cuenta_routes;