import { faBalanceScale, faFileContract } from '@fortawesome/pro-light-svg-icons';
import { faMoneyBill } from '@fortawesome/pro-solid-svg-icons';

const routes = (permisos) => {
    return [
        
    {
      subMenuTitle: "Juridico",
      subRoutes: [
        {
          path: "/citatorios",
          name: "Citatorios",
          icon: faFileContract,
        },
        {
          path: "/finiquitos",
          name: "Finiquitos",
          icon: faMoneyBill
        },
        {
          path: "/demandas",
          name: "Demandas",
          icon: faBalanceScale,
        },
      ],
    },
  ]
};
export default routes;
