import React, { useEffect, useState } from 'react';
import Search from '../ui/Search';
import { FormGroup, Col, Input, Button } from 'reactstrap';
import HttpService from '../../services/HttpService';
import BotonCargando from '../BotonCargando';

const FiltroColaboradores = ({buscar, cargandoBoton = false}) => {
  const [empresas, setEmpresas] = useState([]);
  const [sucursales, setSucursales] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [buscarColaborador, setBuscarColaborador] = useState('');
  const [idEmpresa, setIdEmpresa] = useState(undefined);
  const [idSucursal, setIdSucursal] = useState(undefined);
  const [idDepartamento, setIdDepartamento] = useState("");
  const [cargando, setCargando] = useState(true);

  const onChangeEmpresas = async value => {
    const respuesta = await HttpService.get(`/sucursales/por_empresa?empresa=${value}`);
      
    if(respuesta.data) {
      setSucursales(respuesta.data.resultado);
    }

    setIdEmpresa(value);
    setIdSucursal('');
    setIdDepartamento('');
  }

  const onChangeSucursales = async value => {
    const respuesta = await HttpService.get(`/departamentos/por_sucursal?sucursal=${value}`);
    
    if(respuesta.data) {
      setDepartamentos(respuesta.data.resultado);

    }

    setIdSucursal(value);
    setIdDepartamento('');
  }

  useEffect(() => {
    const loadEmpresas = async () => {
    
      try {
        const respuesta = await HttpService.get('/empresa/obtener_empresas');
        
        if(respuesta.data) {
          const empresasList = respuesta.data.empresas;
          
          setEmpresas(empresasList);
          if (empresasList.length > 0){
            console.log(empresas);
            await onChangeEmpresas(empresasList[0]._id);
            await buscar({buscarColaborador, idEmpresa: empresasList[0]._id, idSucursal: '', idDepartamento: ''});
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
    loadEmpresas().then(() => setCargando(false));
  },[]);



  if(cargando) return null;



  return (
    <FormGroup row>
      <Col sm={12} md={2}>
        <Search onChange={e => setBuscarColaborador(e.target.value)} placeholder='Busca por nombre' />
      </Col>
      <Col sm={12} md={3}>
        <Input
          style={{ height: "100%" }}
          type="select"
          onChange={e => onChangeEmpresas(e.target.value)}
          value={idEmpresa}
          
        >
          <option value={""} key={""}>Todas las empresas</option>
          {
            empresas.map((e) => (
              <option value={e._id} key={e._id}>
                {e.nombre_empresa}
              </option>
            ))
          }
        </Input>
      </Col>
      <Col sm={12} md={3}>
        <Input
          disabled={idEmpresa === undefined}
          style={{ height: "100%" }}
          type="select"
          onChange={e => onChangeSucursales(e.target.value)}
          value={idSucursal}
        >
          <option value={""} key={""}>Todas las sucursales...</option>
          {
            sucursales.map((e, index) => (
              <option value={e._id} key={e._id}>
                {e.nombreSucursal}
              </option>
            ))
          }
        </Input>
      </Col>
      <Col sm={12} md={3}>
        <Input 
          disabled={idSucursal === undefined}
          style={{ height: "100%" }}
          type="select"
          onChange={(e) => setIdDepartamento(e.target.value)}
          value={idDepartamento}
        >
          <option value={""}>Todos los departamento...</option>
          {
            departamentos.map((d) => (
              <option value={d._id} key={d._id}>
                {d.nombreDepartamento}
              </option>
            ))
          }
        </Input>
      </Col>
      <Col sm={12} md={1}>
        <BotonCargando 
          disabled={cargandoBoton}
          cargando={cargandoBoton}
          onClick={async () => await buscar({buscarColaborador, idEmpresa, idSucursal, idDepartamento})}
          texto="Buscar"
        />
      </Col>
    </FormGroup>
  )
}


export default FiltroColaboradores;
