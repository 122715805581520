import React, {useCallback} from 'react';
import colors from '../../../constans/colors';
import { Tr } from '../VacacionesViews/styled';
import moment from 'moment';
import { agregarPermiso } from '../../../store/permisosLaboralesDuck';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCloudDownloadAlt, faTimesCircle } from '@fortawesome/pro-light-svg-icons';

const TableBody = React.memo(({listaPermisosLaborales = [], downloadPdf }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const showUtil = useCallback((permiso) => {
        dispatch(agregarPermiso(permiso))
        history.push('/permisos')
    }, [dispatch]);
    const showExtender = useCallback((permiso)=>{
      dispatch(agregarPermiso(permiso))
      history.push('/extender')
    },[dispatch]);
    const now = moment().format('YYYY-MM-DD');
    
    return(
        <>
            {listaPermisosLaborales.map((permiso, index) => (
              
          <Tr
            key={permiso._id + index}
            
          >
            <td onClick={() => showUtil(permiso)}>{moment(permiso.periodoInicial).utc().format('DD/MM/YYYY')}</td>
            <td onClick={() => showUtil(permiso)}>{
             permiso.extensionPermiso.length > 0? moment(permiso.extensionPermiso[permiso.extensionPermiso.length-1].periodoFinalExt).utc().format('YYYY-MM-DD')
             : moment(permiso.periodoFinal).utc().format('DD/MM/YYYY')
            }</td>
            <td onClick={() => showUtil(permiso)}>{permiso.duracion}</td>
            <td onClick={() => showUtil(permiso)} style={{paddingLeft:30}}>
              <FontAwesomeIcon
                color='#626262'
                icon={permiso.tipoPermiso === 'Con goce de sueldo'?faCheckCircle:faTimesCircle}
                style={{ fontSize: '1.5rem' }}
              />
            </td>
            <td onClick={() => showUtil(permiso)} style={{paddingLeft:15}}>{
              permiso.extensionPermiso.length > 0
            ?<FontAwesomeIcon
                color='#626262'
                icon={faCheckCircle}
                style={{ fontSize: '1.5rem' }}
              />:null}</td>
            <td style={{width:'10%'}}>{
              permiso.extensionPermiso.length >= 0 && permiso.periodoFinal >= now
            ?<Button type="button" onClick={()=> showExtender(permiso)} 
                style={{
                  background:colors.secondary,
                  borderRadius:'4px',
                  lineHeight: '26px',
                  fontSize: '13px'
                }}>Extender</Button>
            :null}
            </td>
            <td style={{flex: 1, flexDirection: 'row', gap: '.5rem'}}>
              
                <Button type="button" onClick={(e) => downloadPdf(e, 'Solicitud', permiso)}
                  style={{
                    background:colors.logoPurple,
                    borderRadius:'4px',
                    lineHeight: '26px',
                    fontSize: '13px',
                    marginRight: '.5rem'
                  }}
                >
                  <FontAwesomeIcon color='#151e5d' icon={faCloudDownloadAlt} /> 
                  Solicitud
                </Button>
              
              
                <Button type="button" onClick={(e) => downloadPdf(e, 'Autorizacion', permiso)}
                  style={{
                    background:colors.logoPurple,
                    borderRadius:'4px',
                    lineHeight: '26px',
                    fontSize: '13px'
                  }}
                >
                  <FontAwesomeIcon color='#151e5d' icon={faCloudDownloadAlt}/> 
                  Autorización
                </Button>
              
            </td>            
          </Tr>
        ))}
        </>
    );
});

export default TableBody;