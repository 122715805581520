import { faCalendarAlt, faCheckDouble, faGavel, faMoneyBillWave, faUserFriends, faNotesMedical } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import SubSideBar from "../../components/layout/SubSidebar/SubSidebar";
import colors from "../../constans/colors";
import routes from "../../routes/juridico-routes";
import JuridicoRoutes from "../../routes/ProtectedRoutes/ViewsRoutes/JuridicoRoutes";
import { LettersContainer } from "../styled";

const Juridico = ({ match, location }) => {
  const {permisos = {}} = useSelector(store => store.user);

  const [ juridico ] = routes(permisos);
  const [actualPage, setActualpage] = useState("");

  const history = useHistory();

  useEffect(() => {
    if (location && location.pathname) {
      const pageIndex = location.pathname.split("/").length;
      setActualpage(location.pathname.split("/")[pageIndex - 1]);
    }
  }, [location]);


  return (
    <section className="grid-container">
      <div style={{ backgroundColor: colors.backgroundSecondary }} className="sub-menu-container">
        <LettersContainer />

        {
          permisos.serviciosJuicios !== 'none' ?
            <SubSideBar
              icon={faGavel}
              title="Jurídico"
              routes={[juridico]}
              match={match}
              className={`${actualPage === "demandas" || actualPage === "citatorios" ? "active" : ""}`}
            /> : null
        }
 
      </div>
      <div className="frame-content">
        <JuridicoRoutes path={match.path} />
      </div>
    </section>
  );
};

export default Juridico;
