import React from "react";
import { useSelector } from "react-redux";
import colors from "../../constans/colors";
import "./OpcionesAlta.css";
const OpcionesAlta = React.memo(({ onChange }) => {
  const { permisos = {} } = useSelector((state) => state.user);

  return (
    <nav className="navbar-nav opciones-alta p-1 pl-4" style={{ backgroundColor: colors.backgroundPrimary, width: "270px", marginLeft: "25px"}}>
      {permisos.colaboradorPersonales === 'none' ? '' : 
        <label className="container d-block p-1">
          <input type="radio" name="opcion" value={1} onChange={onChange} defaultChecked={true}/>
          <span className="checkmark"></span>
          <p className="position-text">Datos personales</p>
        </label>
      }
      {permisos.colaboradorLaborales === 'none' ? '' : 
        <label className="container d-block p-1">
          <input type="radio" name="opcion" value={2} onChange={onChange} />
          <span className="checkmark"></span>
          <p className="position-text">Condiciones Laborales</p>
        </label>
      }
      {permisos.colaboradorNomina === 'none' ? '' : 
        <label className="container d-block p-1">
          <input type="radio" name="opcion" value={3} onChange={onChange} />
          <span className="checkmark"></span>
          <p className="position-text">Informacion de Nómina</p>
        </label>
      }
      {permisos.colaboradorInfonavit === 'none' ? '' : 
        <label className="container d-block p-1">
          <input type="radio" name="opcion" value={16} onChange={onChange} />
          <span className="checkmark"></span>
          <p className="position-text">Promociones</p>
        </label>
      }
      {permisos.colaboradorSimulador === 'none' ? '' : 
        <label className="container d-block p-1">
          <input type="radio" name="opcion" value={13} onChange={onChange} />
          <span className="checkmark"></span>
          <p className="position-text">Simulador Salarial</p>
        </label>
      }
      {permisos.colaboradorOrdinarias === 'none' ? '' : 
        <label className="container d-block p-1">
          <input type="radio" name="opcion" value={12} onChange={onChange} />
          <span className="checkmark"></span>
          <p className="position-text">Prestaciones Ordinarias</p>
        </label>
      }
      {permisos.colaboradorDescuentos === 'none' ? '' : 
        <label className="container d-block p-1">
          <input type="radio" name="opcion" value={4} onChange={onChange} />
          <span className="checkmark"></span>
          <p className="position-text">Descuentos al salario</p>
        </label>
      }
      {permisos.colaboradorInfonavit === 'none' ? '' : 
        <label className="container d-block p-1">
          <input type="radio" name="opcion" value={15} onChange={onChange} />
          <span className="checkmark"></span>
          <p className="position-text">Crédito Infonavit</p>
        </label>
      }
      {permisos.colaboradorUtilitario === 'none' ? '' : 
        <label className="container d-block p-1">
          <input type="radio" name="opcion" value={5} onChange={onChange} />
          <span className="checkmark"></span>
          <p className="position-text">Utilitario</p>
        </label>
      }
      {permisos.colaboradorUniforme === 'none' ? '' : 
        <label className="container d-block p-1">
          <input type="radio" name="opcion" value={6} onChange={onChange} />
          <span className="checkmark"></span>
          <p className="position-text">Uniforme</p>
        </label>
      }
      {permisos.colaboradorExpediente === 'none' ? '' : 
        <label className="container d-block p-1">
          <input type="radio" name="opcion" value={7} onChange={onChange} />
          <span className="checkmark"></span>
          <p className="position-text">Expediente Digital</p>
        </label>
      }
      {permisos.colaboradorActas === 'none' ? '' : 
        <label className="container d-block p-1">
          <input type="radio" name="opcion" value={14} onChange={onChange} />
          <span className="checkmark"></span>
          <p className="position-text">Actas Administrativas</p>
        </label>
      }
      {permisos.colaboradorVacaciones === 'none' ? '' : 
        <label className="container d-block p-1">
          <input type="radio" name="opcion" value={8} onChange={onChange} />
          <span className="checkmark"></span>
          <p className="position-text">Vacaciones</p>
        </label>
      }
      {permisos.colaboradorPermisos === 'none' ? '' : 
        <label className="container d-block p-1">
          <input type="radio" name="opcion" value={9} onChange={onChange} />
          <span className="checkmark"></span>
          <p className="position-text">Permisos Laborales</p>
        </label>
      }
      {permisos.colaboradorIncapacidades === 'none' ? '' : 
        <label className="container d-block p-1">
          <input type="radio" name="opcion" value={10} onChange={onChange} />
          <span className="checkmark"></span>
          <p className="position-text">Incapacidades</p>
        </label>
      }
      {permisos.colaboradorSalud === 'none' ? '' : 
        <label className="container d-block p-1">
          <input type="radio" name="opcion" value={11} onChange={onChange} />
          <span className="checkmark"></span>
          <p className="position-text">Salud Laboral</p>
        </label>
      }
    </nav>
  );
});

export default OpcionesAlta;
