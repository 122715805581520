import React, {useEffect, useState} from 'react';
import colors from "../../constans/colors";
import SubSideBar from "../../components/layout/SubSidebar/SubSidebar";
import mantenimiento_routes from "../../routes/mantenimiento-routes";
import MantenimientoRoutes from "../../routes/ProtectedRoutes/ViewsRoutes/MantenimientoRoutes";
import {LettersContainer} from '../styled';
import { faTrafficCone } from '@fortawesome/pro-light-svg-icons';
const Mantenimiento =({match, location}) =>{
    const [actualPage, setActualpage] = useState('')

    useEffect(() => {
        if (location && location.pathname) {
            const pageIndex = location.pathname.split('/').length;
            setActualpage(location.pathname.split('/')[pageIndex - 1])
        }
    }, [location]);
    
    return(
        <section className="grid-container">
            <div style={{backgroundColor: colors.backgroundPrimary}} className='sub-menu-container'>
                <LettersContainer/>
                <SubSideBar icon={ faTrafficCone } routes={mantenimiento_routes} match={match} title='Mantenimiento'
                    className={`${actualPage === 'alta-masiva' || actualPage === 'facturacion' ? 'active' : ''}`}/>
            </div>
            <div  className='frame-content'>
                <MantenimientoRoutes path={match.path}/>
            </div>
        </section>
    )
}
export default Mantenimiento;