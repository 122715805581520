import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, FormGroup, Label } from 'reactstrap';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CancelButton,
  InputUtils,
  LabelForms,
  SubTitleMenu,
  TextAreaUtils
} from './styled';
import { schemaVacaciones } from '../../../constans/yupSchemas';
import { fetchCreateVacaciones, fetchListaVacaciones, fetchActualizarVacaciones } from '../../../store/vacacionesDucks';
import { ExamLabel } from "../Forms/FormsSaludLaboral/styledSaludLaboral";
import TableCommon from '../VacacionesViews/TableVacaciones';
import { TableContainer } from './styled';
import TableBody from './TableBodyPendientes';
import SweetAlert from '../../ui/SweetAlet';
import { vacacionesDefinition } from './CatalogoVacaciones';
import { SaveButton } from "../../ui/styles/styles";
import { diccionarioDias, lapsoDias } from '../../../helpers';
import { saveAs } from 'file-saver';
//import axios from 'axios';
import BotonCargando from '../../BotonCargando';
import axios from "../../../services/axios";
import DatePicker from "react-datepicker";

const api = process.env.REACT_APP_API_URL;

const PERIODO = {
  fechaInicio: "",
  fechaFin: "",
}

const diasAgenda = [
  {
    id: "lunes",
    nombre: 'L'
  }, 
  {
    id: "martes",
    nombre: 'M'
  }, 
  {
    id: "miércoles",
    nombre: 'MI',
  }, 
  {
    id: "jueves",
    nombre: 'J', 
  },
  {
    id: "viernes",
    nombre: 'V',
  }, 
  {
    id: "sábado",
    nombre: "S",
    
  },
  {
    id: "domingo",
    nombre: 'D'
  }
]; 
    
const VacacionesAdd = ({ history }) => {
  const [periodo , setPeriodo] = useState(PERIODO);
  const [maxFechaFin, setMaxFechaFin] = useState("");
  const [showAdd, setShowAdd] = useState(false);
  const [tablaDias, setTablaDias] = useState([]);
  const [rest, setRest] = useState('');
  const [pend, setPend] = useState(0);
  const [registro, setRegistro] = useState({});
  const [prima, setPrima] = useState('');
  const [notas, setNotas] = useState('');
  const dispatch = useDispatch();
  const [listVacations, setListVacacione] = useState([])
  const { listaVacaciones = [] } = useSelector((state) => state.vacaciones);
  const { condicionesLaborales = {}, _id, pertenencia = {} } = useSelector((state) => state.colaborador);
  const { fechaIngreso = '', horarioTrabajo = [] } = condicionesLaborales;
  
  const headers = {
    headers: {
      token: localStorage.getItem("pv2token"),
    },
  };
  const fecha1 = moment();
  const fecha2 = moment(fechaIngreso);
  const diferencia = fecha1.diff(fecha2, 'years');
  const toggleAdd = () => setShowAdd(!showAdd);
  const { register, handleSubmit, errors, getValues, setValue, control } = useForm({
    resolver: yupResolver(schemaVacaciones),
  });
  const [err, setErr] = useState({});
  const [saving, setSaving] = useState(false);

  const getVacacioneList = async () => {

    console.log(pertenencia)
    let { data } = await axios.get(`/nomina/vacaciones/${pertenencia.empresa}/`, headers);
    setListVacacione(data.response)
  };

  useEffect(() => {
    getVacacioneList()
  }, [])


  useEffect(() => {
    if (!listVacations.length) return
    let diasAno = listVacations.find(f => f.antiguedad == diferencia)
    if (!diasAno) {
      diasAno =  vacacionesDefinition(diferencia)
    } else {
      diasAno = diasAno.dias
    }
    if (listaVacaciones.length === 0 && diferencia > 0) {
      setRest(diasAno);
      setPend(diasAno);
    } else {
      setRest(diasAno);
      listaVacaciones.forEach(element => {
        if (element.anosTrabajando === diferencia) {
          setPend(element.numeroDiasPendientes);
          setRegistro(element)
          setTablaDias(element.periodos)
          setPrima(element.prima);
          setNotas(element.notas);
        }
      });
    }
  }, [dispatch, listVacations]);

  useEffect(() => {
    if (Object.keys(errors).length) setErr(errors);
  }, [errors]);

  const mensajeError = async (error) => {
    await SweetAlert({
      icon: 'error',
      title: error,
    })
  }
  
  const onSubmit = async (data) => {
    try {
      setSaving(true);
      data.periodos = tablaDias;
      data.numeroDiasPendientes = pend;

      if (Object.keys(registro).length === 0) {
        dispatch(fetchCreateVacaciones(data));
        dispatch(fetchListaVacaciones());
        history.push('/');
      } else {
        data.id = registro._id;
        dispatch(fetchActualizarVacaciones(data));
        dispatch(fetchListaVacaciones());
        history.push('/');
      }
    } catch (error) { 
      await SweetAlert({ title: 'Error!', text: 'Ocurrió un error!', icon: 'error' });
      console.log(error);
    } finally {
      setSaving(false);
    }
  };

  const addToTable = async () => {
    const { periodos } = getValues();
    let firstPeriodFlag = 1; //1 = verde, 2 = amarillo
    let sixMontsFlag = 1; // 1 = verde, 2 = amarillo
    //const ingreso = fecha1.diff(fecha2, 'years', true);
    const actual = registro.anosTrabajando ? registro.anosTrabajando : diferencia + 1.5;
    const sixMonts = registro.anosTrabajando ? registro.anosTrabajando : diferencia + 0.5;
    const aniversario = moment(fechaIngreso);
    const fInicio = moment(periodos.inicioPeriodo);
    const fFinal = moment(periodos.finPeriodo);
    const diffInicio = fInicio.diff(aniversario, 'years', true);
    const diffFinal = fFinal.diff(aniversario, 'years', true);

    try { 
      if(!fechaIngreso) {
        await mensajeError('No se encontró fecha de ingreso');
        return;
      }

      if (actual > diffInicio || actual > diffFinal) {
        if (diffInicio < diffFinal) {
          if (
            periodos.inicioPeriodo === '' ||
            periodos.finPeriodo === ''
          ) {
            await SweetAlert({
              icon: 'error',
              title: 'Favor de llenar los campos',
            })
          } else {
            if (horarioTrabajo.length === 0) {
              await SweetAlert({
                icon: 'error',
                title: 'Se debe asignar horario laboral primero',
              });
            } else {
              const horario = horarioTrabajo[0].days;
              const arrayDias = await diccionarioDias(horario);
              const fechas = await lapsoDias(periodos.inicioPeriodo, periodos.finPeriodo);
              const reg = [];
              fechas.forEach(fechas => {
                arrayDias.forEach(dias => {
                  if (fechas.day === dias) {
                    reg.push(fechas);
                  }
                });
              });
              const diff = reg.length;
              
              if (diffInicio >= sixMonts) {
                sixMontsFlag = 2;
                await SweetAlert({
                  icon: 'warning',
                  title: 'Vacaciones deben ser dentro de los 6 meses',
                });

                return;
              }
              
              let lastDate = 0;
              let diffDate = 0;
              if (tablaDias.length > 0) {
                tablaDias.forEach(element => {
                  lastDate = moment(element.finPeriodo);
                });
                diffDate = lastDate.diff(aniversario, 'years', true);
              }
              if (lastDate != 0 && diffInicio < diffDate) {
                await SweetAlert({
                  icon: 'error',
                  title: 'Ya hay vacaciones en este periodo',
                });
              } else {
                periodos.numeroDias = diff + 1;
                periodos.firstPeriodFlag = firstPeriodFlag;
                periodos.sixMontsFlag = sixMontsFlag;
                setTablaDias((actualState) => [...actualState, periodos]);
                const cleanInputData = {
                  inicioPeriodo: "",
                  finPeriodo: "",
                  notificacion: "",
                };
                setValue("diasPendientes", cleanInputData, { shouldDirty: true });
                setShowAdd(false)
              }
            }
          }
        } else {
          await SweetAlert({
            icon: 'error',
            title: 'Error en el rango de fechas',
          });
        }
      } else {
        await SweetAlert({
          icon: 'error',
          title: 'Ya no puedes asignar vacaciones a este año',
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = (index, data) => {
      const filtro = tablaDias.filter(
        (obj) => obj != data
      );
      setTablaDias(() => filtro);
      console.log(pend);
      const suma = pend + data.numeroDias;
      setPend(suma)
      setShowAdd(false)
  };

  const handleChange = (value) => {
    const valor = value.target.value;
    const diasAno = vacacionesDefinition(valor);
    const index = listaVacaciones.findIndex(x => x.anosTrabajando == parseInt(valor, 10));
    if (index !== -1) {
      listaVacaciones.forEach(element => {
        if (element.anosTrabajando === parseInt(valor, 10)) {
          setRegistro(element)
          setRest(diasAno);
          setNotas(element.notas);
          setPrima(element.prima);
          if (element.periodos.length > 0) {
            setTablaDias(element.periodos);
            setPend(element.numeroDiasPendientes);
          } else {
            setPend(diasAno);
          }
        }
      });
    } else {
      setPend(diasAno);
      setRest(diasAno);
      setTablaDias([]);
      setRegistro({})
    }
  }

  const downloadPdf = async (e, data) => {
    e.preventDefault();
    data.antiguedad = registro.anosTrabajando;
    const file = await axios.post(`${api}/vacaciones/generar-constancia`, { ...data, colaborador: _id }, headers)


    let byteString = atob(file.data);

    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    let blob = new Blob([ia], { type: 'application/pdf' });
    saveAs(blob, `constancia-${moment().format('YYYYMMDD')}`)
  }

  const onChangeFechaInicio = value => {
    value = new Date(value);
    let diasVacaciones = 0;
    let _pend = 0;
    let fechaInicio = moment(value);
    let _maxFechaFin = "";

    while (!_maxFechaFin) {
      const dia = moment(fechaInicio).format('dddd');
      const { nombre } = diasAgenda.find(d => d.id === dia);
      
      if(horarioTrabajo.some(horario => horario.days.some(d => d === nombre))) {
        diasVacaciones += 1;
        
        _pend = pend - diasVacaciones;

        if(_pend <= 0) {
          _maxFechaFin = moment(fechaInicio).format('YYYY-MM-DD');
        }
      }

      fechaInicio = moment(fechaInicio).add(1, 'days');
    }
    console.log(_maxFechaFin)
    setMaxFechaFin(_maxFechaFin);
    setPeriodo({
      ...periodo, 
      fechaInicio: value,  
      fechaFin: "",
    });
  }

  const onChangeFechaFin = value => {
    value = new Date(value);
    let diasVacaciones = 0;
    let { fechaInicio } = periodo;
    const fechaFin = moment(value);

    fechaInicio = moment(fechaInicio);
    
    while (fechaInicio <= fechaFin) {
      const dia = moment(fechaInicio).format('dddd');
      const { nombre } = diasAgenda.find(d => d.id === dia);
      
      if(horarioTrabajo.some(horario => horario.days.some(d => d === nombre))) {
        diasVacaciones += 1;
      }

      fechaInicio = moment(fechaInicio).add(1, 'days');
    }

    const _pend = pend - diasVacaciones;

    setPend(_pend);
    setPeriodo({...periodo, fechaFin: value});
  }
  
  return (
    <Form onSubmit={handleSubmit(onSubmit)} style={{ marginLeft: '30px' }}>
      <FormGroup row>
        <Col sm={12} style={{ marginBottom: 5, marginLeft: '-17px' }}>
          <SubTitleMenu 
            style={{ color: '#2B388F', fontWeight: 'bold', fontSize: '20px' }}
          >
            Calendarización de Vacaciones
          </SubTitleMenu>
        </Col>
        <Label sm={3} style={{ marginBottom: '12px' }}>
          <LabelForms>Años Trabajando</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4, push: 12 }}>
          <InputUtils
            name='anosTrabajando'
            type='number'
            defaultValue={diferencia}
            placeholder='Ej. 5'
            ref={register}
            onChange={(value) => handleChange(value)}
          />
        </Col>
        <Col sm={4}></Col>
        <Label sm={3} style={{ marginBottom: '12px' }}>
          <LabelForms>Días Correspondientes</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <InputUtils
            name='diasCorrespondientes'
            type='number'
            defaultValue={rest}
            placeholder='Ej. 10'
            ref={register()}
          />
        </Col>
        <Col sm={4}></Col>
        <Label sm={3} style={{ marginBottom: '12px' }}>
          <LabelForms>Días Pendientes</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <InputUtils
            className='pendientes'
            name='diasPend'
            type='number'
            defaultValue={pend}
            placeholder='Ej. 5'
            ref={register}
          />
        </Col>
        <Col sm={4}></Col>
        <Label sm={3} style={{ marginBottom: '12px' }}>
          <LabelForms>Prima Vacacional (%)</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <InputUtils
            name='prima'
            type='number'
            defaultValue={25}
            placeholder='Ej. 100'
            ref={register}
          />
        </Col>
        <Col sm={4}></Col>
        <Col sm={3} style={{ marginBottom: '12px' }}>
          <LabelForms>Notas</LabelForms>
        </Col>
        <Col sm={{ offset: 1, size: 4 }}>
          <TextAreaUtils
            name='notas'
            placeholder='Ej. Notas'
            value={notas}
            ref={register}
          />
        </Col>
        <Col sm={4}></Col>
      </FormGroup>
      <FormGroup row >
        <Col sm={12} style={{ marginBottom: 5, marginLeft: '-17px' }}>
          <SubTitleMenu
            style={{ color: '#2B388F', fontWeight: 'bold', fontSize: '20px' }}
          >Planeación de Vacaciones</SubTitleMenu>
        </Col>
        <TableContainer style={{ marginLeft: 30, marginTop: 10 }}>
          <TableCommon
            keysAceepted={['Inicio', 'Fin', 'Notificación', 'Días', 'Constancia', '', '', '']}
            extraTag={{ key: 'Valor Total', value: 1200 }}
            striped
          >
            <TableBody tablaDias={tablaDias} handleDelete={handleDelete} downloadPdf={downloadPdf} />
          </TableCommon>
        </TableContainer>
        <ExamLabel sm={3} onClick={e => toggleAdd()} style={{ cursor: 'pointer' }}>+ Agregar</ExamLabel>
      </FormGroup>
      <div style={{width: "100%"}}>
        Días agenda: { }
      </div>
      {showAdd
        ? <FormGroup row>
          <Label sm={3} style={{ marginBottom: '12px' }}>
            <LabelForms>Inicio Vacaciones*</LabelForms>
          </Label>
          <Col sm={{ offset: 1, size: 4 }}>
            <Controller
              name='periodos.inicioPeriodo'
              control={control}
              innerRef={register()}
              render={({ onChange, value }) => (
                <DatePicker
                  selected={value}
                  onChange={date => {
                    onChange(date);
                    onChangeFechaInicio(date);
                  }}
                  dateFormat={'dd/MM/yyyy'}
                  minDate={new Date(fechaIngreso)}
                />
              )}
            />
          </Col>
          <Col sm={4}></Col>
          <Label sm={3} style={{ marginBottom: '12px' }}>
            <LabelForms>Final Vacaciones*</LabelForms>
          </Label>
          <Col sm={{ offset: 1, size: 4 }}>
            <Controller
              name='periodos.finPeriodo'
              control={control}
              innerRef={register()}
              render={({ onChange, value }) => (
                <DatePicker
                  selected={value}
                  onChange={date => {
                    onChange(date);
                    onChangeFechaFin(date);
                  }}
                  dateFormat={'dd/MM/yyyy'}
                  minDate={new Date(periodo.fechaInicio)}
                  maxDate={new Date(maxFechaFin)}
                  //disabled={!periodo.fechaInicio}
                />
              )}
            />
          </Col>
          <Col sm={4}></Col>
          <Label sm={3} style={{ marginBottom: '12px' }}>
            <LabelForms>Notificación</LabelForms>
          </Label>
          <Col sm={{ offset: 1, size: 4 }}>
            <Controller
              name='periodos.notificacion'
              control={control}
              innerRef={register()}
              render={({ onChange, value }) => (
                <DatePicker
                  selected={value}
                  onChange={onChange}
                  dateFormat={'dd/MM/yyyy'}
                />
              )}
            />
          </Col>
          <Col sm={4}></Col>
          <Col sm={3} className="text-center">
            <SaveButton type="button" onClick={addToTable} >
              + Agregar
          </SaveButton>
          </Col>
        </FormGroup>
        : null
      }
      <FormGroup row>
        <Col sm={{ offset: 6, size: 3 }}>
          <CancelButton
            onClick={() => {
              history.push('/');
            }}
            style={{ marginLeft: 'auto' }}>
            Cancelar
          </CancelButton>
        </Col>
        <Col sm={3}>
          <BotonCargando
            cargando={saving}
            disabled={saving}
            texto='Guardar'
            type='submit'
          />
        </Col>
      </FormGroup>
    </Form>
  );
};

export default VacacionesAdd;