import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import events from './calendarioDuck';
import userReducer from './userDuck';
import empresaReducer from './empresasDuck';
import sucursalReducer from "./sucursalDuck";
import departamentosReducer from './departamentosDucks'
import colaboradorReducer from './colaboradorDucks';
import rolesReducer from "./rolesDucks";
import citatorioReducer from './citatorioDuck';
import usuariosReducer from "./usuariosDuck";
import demandasReducer from "./demandaDuck";
import utilitarioReducer from './utilitarioDucks';
import vacacionesReducer from "./vacacionesDucks";
import permisosReducer from './permisosLaboralesDuck';
import incapacidadesReducer from './incapacidadesDuck';
import descuentosReducer from './descuentosDuck';
import actasReducer from './actasDuck';
import audienciasReducer from './audienciasDuck';
import finiquitosReducer from './finiquitosDuck';
import promocionReducer from './promocionesDuck';

const appReducer = combineReducers({
  calendario : events,
  form: formReducer,
  user: userReducer,
  empresas : empresaReducer,
  sucursal : sucursalReducer,
  departamentos:departamentosReducer,
  colaborador:colaboradorReducer,
  roles: rolesReducer,
  citatorio:citatorioReducer,
  usuarios: usuariosReducer,
  demanda: demandasReducer,
  utilitario:utilitarioReducer,
  vacaciones:vacacionesReducer,
  permisos:permisosReducer,
  incapacidades:incapacidadesReducer,
  descuentos: descuentosReducer,
  promociones: promocionReducer,
  actas: actasReducer,
  audiencias: audienciasReducer,
  finiquitos: finiquitosReducer
});

const rootReducer = (state, action) =>{
  if (action.type === 'LOGOUT') {
    localStorage.removeItem('pv2token');
    localStorage.removeItem('permisos');
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer;
