export default {
    logoPurple: '#6274D6',
    logoOrange: '#FF8A19',
    logoLightOrange: '#FFBD3F',    
    logoBlue: '#A6D2EF',
    logoTextBlue: '#1D194B',
    primary:'white',
    secondary: '#FFBD3F',
    //secondary:'#2B388F',
    borderColor:'#979797',
    textColorPrimary:'#767676',
    textColorSecondary:'#797979',
    labelText:'#7B7B7B',
    submenuTextColor:'#3C3C3C',
    cardSecondaryText:'#9B9B9B',
    buttonColorPrimary:'#A6AFB9',
    buttonColorSecondary:'#B2BDC8',
    backgroundPrimary: '#A6D2EF',
    backgroundSecondary: '#FFBD3F',
    //backgroundSecondary: '#AFD7FF',
    iconsColors:'#969696',
    tashColor:'#3C3C3C',
    closeStatus:'#E96060',
    yellow:'#ffc107'
}