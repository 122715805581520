import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';
import { Input, FormFeedback, FormText } from 'reactstrap';
import { fetchCatalogos } from '../../store/empresasDuck';
let loading = true;
const SelectRegimenFiscal = React.memo(
  ({ control, defaultValue = '', ...rest }) => {
    const { catalogosSat = [] } = useSelector((state) => state.empresas);
    const [options, setoptions] = useState([])
    const dispatch = useDispatch();
    useEffect(() => {
      (async () => {
        try {
          if (catalogosSat.length === 0)
            await dispatch(fetchCatalogos({ tipoCatalogo: 'regimenFiscal' }));
            const data =
            catalogosSat.length > 0
              ? catalogosSat.map(({ descripcion = '' }) => {
                  return { value: descripcion, label: descripcion };
                })
              : [];
              setoptions(()=>data);
              loading = false;
        } catch (error) {
          console.log(error);
        }
      })();
    }, [dispatch, catalogosSat.length, catalogosSat]);

    if (!control || options.length === 0 || loading) return <span>...</span>;
    return (
      <>
        <Controller
          control={control}
          {...rest}
          defaultValue=''
          render={() => {
            return (
              <Input {...rest}>
                <option value=''>Seleccione opcion</option>
                {defaultValue
                  ? options.map(({ value, label }, index) => {
                      return value ? (
                        <option
                          key={value + index}
                          value={value}
                          selected={defaultValue === value ? true : false}
                        >
                          {label}
                        </option>
                      ) : null;
                    })
                  : options.map(({ value, label }, index) => {
                      return value ? (
                        <option key={value + index} value={value}>
                          {label}
                        </option>
                      ) : null;
                    })}
              </Input>
            );
          }}
        />
        <FormFeedback>{rest.error}</FormFeedback>
        <FormText>{rest.formText}</FormText>
      </>
    );
  }
);

export default SelectRegimenFiscal;
