import {
  faIdCard,
  faUsers,
  faMoneyBillWave,
  faCog,
  faBuilding,
  faGavel,
  faTrafficCone,
} from '@fortawesome/pro-regular-svg-icons';

const routes = [
  {
    path: '/perfil', //RETIRADO HASTA QUE SE IMPLEMENTE
    icon: faIdCard,
    name: 'perfil'
  },
  {
    path: '/recursos-humanos/lista-colaboradores',
    name: 'recursos-humanos',
    icon: faUsers,
  },/*
  {
    path: '/nomina/configuracion-nomina',
    name: 'nomina',
    icon: faMoneyBillWave,
  },
  {
    path: '/juridico/citatorios',
    name: 'juridico',
    icon: faGavel,
  },*/
  {
    path: '/empresa',
    name: 'empresa',
    icon: faBuilding,
  },
  {
    path: '/cuenta/empresa',
    name: 'cuenta',
    icon: faCog,
  },
  /*
  {
     path: '/mantenimiento',
     name: 'mantenimiento',
     icon: faTrafficCone,
   }
   */
];
export default routes;
