import styled from 'styled-components';
import logoImage from '../../../../assets/img/nomizorRojo.png';

export const LogoContainer = styled.div`
  place-self: center;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: white;
  background-image: url(${logoImage});  
  border-radius: 10%;
  margin-top: 28px;
  margin-bottom: 20px;
  min-height: 50px;
  width: 50px;
`;